var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('base-button',{staticClass:"flotingBtn",class:[_vm.isActive ? 'tagBtn2' : 'tagBtn'],attrs:{"icon":"","type":"primary"},on:{"click":function($event){_vm.isActive = !_vm.isActive}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-bold-right"})])]),_c('base-button',{staticClass:"flotingBtn2",class:[_vm.isActive ? 'tagBtn' : 'tagBtn2'],attrs:{"icon":"","type":"primary"},on:{"click":_vm.toggleClass}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-bold-left"})])]),_c('Sidebar',{class:{ tagBtn: _vm.isActive }}),_c('div',{staticClass:"main-content",class:{ DashboardBody: _vm.isActive }},[_c('SampleNavbar'),_c('base-header',{staticClass:"pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"href":"#"}},[_vm._v("Service")]),_c('b-breadcrumb-item',{attrs:{"href":"#","active":""}},[_vm._v("Service List")])],1)],1),_c('b-container',{staticClass:"mt--7",attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"md":"10"}}),_c('b-col',{attrs:{"md":"2"}},[_c('base-button',{attrs:{"block":"","type":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'add-service' })}}},[_vm._v("Add Service")])],1)],1),_c('b-card',{attrs:{"type":"default","no-body":""}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'before')?_c('span',[_c('img',{attrs:{"src":props.row.service_img_link,"width":"50%"}})]):_vm._e(),(props.column.field == 'pagedetails')?_c('span',[_c('base-button',{attrs:{"icon":"","type":"primary","size":"sm"},on:{"click":function($event){return _vm.longDetails(props.row.service_pagedetails)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('b-icon',{attrs:{"icon":"eye-fill","scale":"1"}})],1)])],1):_vm._e(),(props.column.field == 'after')?_c('span',[_c('base-button',{attrs:{"icon":"","type":"primary","size":"sm"},on:{"click":function($event){return _vm.$router.push({
                    name: 'add-service',
                    params: { serId: props.row.service_id },
                  })}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-edit"})])])],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1),_c('b-modal',{attrs:{"id":"bv-modal-long-details","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticStyle:{"margin":"0px"}},[_vm._v("View Long Details")])]},proxy:true}])},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.longData)}})]),_c('Footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }