<template>
  <div class="wrapper">
    <base-button
      icon
      type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon
      type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{ tagBtn: isActive }" />
    <div class="main-content" :class="{ DashboardBody: isActive }">
      <SampleNavbar />
      <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
        <b-breadcrumb>
          <b-breadcrumb-item href="#">Service</b-breadcrumb-item>
          <b-breadcrumb-item href="#" active>Service List</b-breadcrumb-item>
        </b-breadcrumb>
      </base-header>
      <b-container fluid class="mt--7">
        <b-row class="mb-2">
          <b-col md="10"></b-col>
          <b-col md="2"
            ><base-button
              block
              type="primary"
              @click="$router.push({ name: 'add-service' })"
              >Add Service</base-button
            ></b-col
          >
        </b-row>
        <b-card type="default" no-body>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :line-numbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
            }"
          >
            <template slot="table-row" slot-scope="props" style="w">
              <span v-if="props.column.field == 'before'">
                <img :src="props.row.service_img_link" width="50%" />
              </span>
              <span v-if="props.column.field == 'pagedetails'">
                <base-button
                  icon
                  type="primary"
                  size="sm"
                  @click="longDetails(props.row.service_pagedetails)"
                >
                  <span class="btn-inner--icon"
                    ><b-icon icon="eye-fill" scale="1"></b-icon
                  ></span>
                </base-button>
              </span>
              <span v-if="props.column.field == 'after'">
                <base-button
                  icon
                  type="primary"
                  size="sm"
                  @click="
                    $router.push({
                      name: 'add-service',
                      params: { serId: props.row.service_id },
                    })
                  "
                >
                  <span class="btn-inner--icon"
                    ><i class="far fa-edit"></i
                  ></span>
                </base-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-card>
      </b-container>
      <b-modal id="bv-modal-long-details" hide-footer>
        <template #modal-title>
          <h2 style="margin: 0px">View Long Details</h2>
        </template>
        <div v-html="longData" style=""></div>
      </b-modal>
      <Footer />
    </div>
  </div>
</template>
<script>
import Sidebar from "./../../Starter/Sidebar";
import SampleNavbar from "./../../Starter/SampleNavbar";
import Footer from "./../../Starter/SampleFooter";
import { API_URL, SITE_URL } from "../../../router/api";
export default {
  name: "service-list",
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data() {
    return {
      isActive: true,
      columns: [
        {
          label: "Service Image",
          field: "before"
        },
        {
          label: "Service Title",
          field: "service_title"
        },
        {
          label: "Service Url",
          field: "service_url"
        },
        {
          label: "Small Details",
          field: "service_smalldetails"
        },
        {
          label: "Long Details",
          field: "pagedetails"
        },
        {
          label: "Actions",
          field: "after"
        }
      ],
      longData: null
    };
  },
  computed: {
    rows() {
      return this.$store.state.servicelist === null
        ? []
        : this.$store.state.servicelist;
    }
  },
  methods: {
    toggleClass: function(event) {
      this.isActive = !this.isActive;
    },
    longDetails(data) {
      this.longData = data;
      this.$bvModal.show("bv-modal-long-details");
    }
  },
  beforeCreate() {
    this.$store.dispatch("getServiceList");
  },
  mounted() {}
};
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
#bv-modal-long-details {
  .modal-body {
    img {
      width: 100%;
    }
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 900px !important;
    }
  }
}
</style>

